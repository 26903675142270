import { forwardRef, ForwardedRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ISkills } from './Skills.interface';

import { SKILLS } from './constants';

import styles from './Skills.module.scss';

const SkillsComponent = forwardRef(({ ...rest }: ISkills, ref: ForwardedRef<HTMLDivElement>) => {
  const startDate: number = 2018;
  const newDate: number = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <section
      className={classNames(styles.Skills, 'section')}
      ref={ref}
      {...rest}
    >
      <article className="container">
        <h2 className={styles.SkillsTitle}>{t('skills.title')}</h2>
        <p className={classNames(styles.SkillsText, 'textUnderlineLine')}>
          {t('skills.text', { data: newDate - startDate })}
        </p>
        <ul className={styles.SkillsList}>
          {SKILLS.map((value, index) => (
            <li key={index}>{value}</li>
          ))}
        </ul>
      </article>
    </section>
  );
});

export default SkillsComponent;
