export const SKILLS = [
  'HTML5, CSS3',
  'Stylus, SCSS/SASS',
  'jQuery, Vanilla JS, TS',
  'React, Redux, RTK Query',
  'PHP, Next.js',
  'WordPress, WooCommerce, ACF PRO',
  'Gulp, Webpack',
  'GitHub, GitLab, Bitbucket',
  'Adobe XD, Figma'
];
