import { useEffect, useRef } from 'react';
import styles from './Preloader.module.scss';

import { ReactComponent as ValikIcon } from './Valik.svg';

const PreloaderComponent = (): JSX.Element => {
  const iconLogo = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const patrhs: any = iconLogo.current?.children[0].children;
    [...patrhs].forEach((path: any, index: number) => {
      const dashes: number = path.getTotalLength();
      const delay: number = index / 2;
      path.setAttribute('style', `--dashers: ${dashes}; --delay: ${delay}s;`);
    });
  }, [iconLogo]);

  return (
    <div className={styles.Loader} ref={iconLogo}>
      <ValikIcon className={styles.Svg} />
    </div>
  );
};

export default PreloaderComponent;
