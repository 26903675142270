import { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IProject } from '../../../interfaces/portfolio';

import styles from './ProjectComponent.module.scss';

const ProjectComponent = ({ link, name, img, text, technologies }: IProject): JSX.Element => {
  const { t } = useTranslation();
  const textTechnologies = useMemo(() => {
    return technologies.join(', ');
  }, []);

  return (
    <article className={styles.ProjectComponent}>
      <a
        className={styles.ProjectComponent__link}
        href={link}
        target="_blank"
        title={name}
      >
        <img
          className={styles.ProjectComponent__image}
          src={img}
          alt={name}
          loading="lazy"
        />
        <div className={styles['ProjectComponent__info']}>
          <h3 className={styles.ProjectComponent__title}>{name}</h3>
          {text && <p className={classNames(styles.ProjectComponent__text, 'textUnderlineLine')}>{t(text)}</p>}
          <p className={styles.ProjectComponent__text}>{textTechnologies}</p>
        </div>
      </a>
    </article>
  );
};

export default ProjectComponent;
