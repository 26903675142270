import { ForwardedRef, forwardRef, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ButtonCategory from './ButtonCategory';
import ProjectComponent from './ProjectComponent';

import { PORTFOLIO } from './constants';

import { ITechnologiesProject, IPortfolioInterface } from './Portfolio.interface';

import styles from './Portfolio.module.scss';

const DEFAULT_FILTER = {
  name: 'All',
  id: 'all'
};

const PortfolioComponent = forwardRef(
  ({ className, ...rest }: IPortfolioInterface, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const { t } = useTranslation();

    const [filterActive, setFilterActive] = useState<string>(DEFAULT_FILTER.id);

    const technologies = useMemo(() => {
      const result: Array<ITechnologiesProject> = [DEFAULT_FILTER];
      PORTFOLIO.forEach((project) => {
        project.technologies.forEach((technology) => {
          if (!result.some((cat) => cat.name === technology)) {
            result.push({
              name: technology,
              id: technology
            });
          }
        });
      });
      return result;
    }, []);

    const filterProject = useMemo(() => {
      if (filterActive === 'all') {
        return PORTFOLIO;
      }
      const filteredProjects = PORTFOLIO.filter((project) => project.technologies.includes(filterActive));
      return filteredProjects;
    }, [filterActive]);

    return (
      <section
        className={classNames(className, styles.Portfolio, 'section')}
        {...rest}
        ref={ref}
      >
        <div className="container">
          <h2 className={classNames(styles.PortfolioTitle, 'textUnderlineLine')}>{t('portfolio.title')}</h2>
          {technologies && (
            <div className={styles['Portfolio__wrapper-filter']}>
              {technologies.map((cat) => (
                <ButtonCategory
                  key={cat.id}
                  active={cat.id === filterActive}
                  name={cat.name}
                  id={cat.id}
                  setFilter={setFilterActive}
                />
              ))}
            </div>
          )}
          <div className={styles.PortfolioList}>
            {filterProject.map((value, index) => (
              <ProjectComponent
                {...value}
                key={value.name}
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
);

export default PortfolioComponent;
