import { ISocial } from './interfaces/social';

export const SOCIAL: Array<ISocial> = [
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/vvalikpavlenko/'
  },
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/vvalikpavlenko/'
  },
  {
    title: 'Github',
    link: 'https://github.com/vvalikpavlenko'
  },
  {
    title: 'Telegram',
    link: 'https://t.me/vvalikpavlenko'
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/vvalikpavlenko/'
  }
];
