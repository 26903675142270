import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tabs from '../Tabs';

import { IArrayEducation, IEducation, ISectionExperience } from './Education.interface';

import styles from './Education.module.scss';


const EducationComponent = forwardRef(({ className, ...rest }: IEducation,
  ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  const { t } = useTranslation();
  const arrayEducation: Array<IArrayEducation> = t('education.education', { returnObjects: true });
  const arrayProfessional: Array<IArrayEducation> = t('education.professional', { returnObjects: true });
  const arrayVolunteer: Array<IArrayEducation> = t('education.volunteer', { returnObjects: true });

  const tabs = [
    {
      title: t('education.professionalTitle'),
      key: 'professional',
      body: (<SectionExperience arraySection={arrayProfessional}/>)
    },
    {
      title: t('education.educationTitle'),
      key: 'education',
      body: (<SectionExperience arraySection={arrayEducation}/>)
    },
    {
      title: t('education.volunteerTitle'),
      key: 'volunteer',
      body: (<SectionExperience arraySection={arrayVolunteer}/>)
    }
  ];

  return (
    <section className={classNames('section', className)} {...rest} ref={ref}>
      <article className="container">
        <Tabs
          title="Valentyn Pavlenko education"
          tabs={tabs}
        />
      </article>
    </section>
  );
});
export default EducationComponent;

const SectionExperience = ({ arraySection }: ISectionExperience): JSX.Element => {
  return (
    <ul className={styles.EducationList}>
      {arraySection.map((value, index) => (
        <ListEducation
          date={value.date}
          companies={value.companies}
          position={value.position}
          key={index}
        />
      ))
      }
    </ul>
  );
};

const ListEducation = ({ date, companies, position }: IArrayEducation): JSX.Element => {
  return (
    <li className={styles.EducationListItem}>
            <span className={styles.EducationListItemDate}>
                {date}
            </span>
      {position} {companies && (<span>{companies}</span>)}
    </li>
  );
};
