import { useEffect, useState } from 'react';

import { ITextTyped } from './TextTypedProp.interface';

const TextTyped = ({ textUser, speed }: ITextTyped) => {
  const [logoTextState, setLogoTextState] = useState<string>(textUser.substr(1));
  const [logoTextEnd, setLogoTextEnd] = useState<string>('');

  useEffect(() => {
    setTimeout(() => {
      setLogoTextState((prevState: string) => prevState.substr(1));
      setLogoTextEnd((prevState: string) => prevState + logoTextState.substr(0, 1));
    }, speed);
  }, [logoTextEnd]);

  return logoTextEnd;
};

export default TextTyped;
