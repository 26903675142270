import React, { useEffect, useState } from 'react';

import { ArrayTypesProps } from './ArrayTypes.interface';

const ArrayTypesComponent = ({ array, speed }: ArrayTypesProps) => {
  const [numberTextActive, setNumberTextActive] = useState(0);
  const [reverseTextActive, setReverseTextActive] = useState(true);
  const [textState, setTextState] = useState(array[numberTextActive].split(''));
  const [textEnd, setTextEnd] = useState('');

  useEffect(() => {
    setTimeout(() => {
      if (!reverseTextActive) {
        if (textEnd.length >= textState.length) {
          setReverseTextActive(prevState => !prevState);
        } else {
          if (textEnd.length === 0) {
            setTextEnd(textState[0]);
          } else {
            setTextEnd(prevState => prevState + textState[prevState.length]);
          }
        }
      } else {
        if (textEnd.length === 0) {
          setNumberTextActive(prevState => prevState === array.length - 1 ? 0 : ++prevState);
          setTextState(array[numberTextActive].split(''));
          setReverseTextActive(prevState => !prevState);
        } else {
          setTextEnd((prevState: string) => prevState.substr(0, prevState.length - 1));

        }
      }
    }, speed);

  }, [textEnd, reverseTextActive]);
  return (
    <>
      {textEnd}
    </>
  );
};
export default ArrayTypesComponent;


