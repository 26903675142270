import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IHeader, IMenuProps } from './Header.interface';

import TextTyped from '../TextTyped';

import styles from './Header.module.scss';

const HeaderComponent = ({ logoText, menu, className, ...rest }: IHeader) => {
  const logoSymbol: string = logoText.substr(0, 1);
  const [menuStatic, setMenuStatic] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const { i18n } = useTranslation('', {});

  const [language, setLanguage] = useState(i18n.language === 'ua' ? 'en' : 'ua');

  const changeLanguage = () => {
    setLanguage(i18n.language);
    i18n.changeLanguage(language);
  };

  const handleScroll = (e: Object) => {
    if (window.scrollY > 100) {
      setMenuStatic(true);
    } else {
      setMenuStatic(false);
    }
  };
  window.addEventListener('scroll', handleScroll);

  const resize = () => {
    if (window.innerWidth <= 768) {
      setMobileMenu(prevState => false);
    } else {
      setMobileMenu(prevState => true);
    }
    setActiveMenu(false);
  };
  useEffect(resize, []);
  window.addEventListener('resize', resize);

  const handleMenuBtn = () => {
    setActiveMenu(prevState => !prevState);
    setMobileMenu(prevState => !prevState);
  };

  return (
    <header
      className={classNames(className,
        styles.Header, {
          [styles.HeaderStatic]: menuStatic,
          [styles.HeaderActiveMenu]: activeMenu
        })}
      {...rest}
    >
      <div className={classNames(styles.HeaderContainer, 'container')}>
        <a href="/" className={classNames(styles.HeaderLogo, {
          [styles.HeaderLogoStatic]: menuStatic
        })}>
          <span className={styles.HeaderLogoSpan}>{logoSymbol}</span>
          <TextTyped speed={300} textUser={logoText}/>
        </a>
        <button className={classNames(styles.HeaderBtnMenu, {
          [styles.HeaderBtnMenuActive]: mobileMenu
        })} onClick={handleMenuBtn}>
          <span className={styles.HeaderBtnMenuSpan}></span>
        </button>
        {mobileMenu && (
          <nav className={styles.HeaderNav}>
            <ul className={styles.HeaderNavMenu}>
              {menu.map((value, index) => (
                <ListMenu
                  key={index}
                  info={value}
                  onClick={handleMenuBtn}
                />
              ))}
              <li className={styles.HeaderNavItem}>
                <button
                  className={classNames(styles.HeaderNavBtn, styles.HeaderNavLanguage)}
                  onClick={changeLanguage}
                >
                  {language}
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default HeaderComponent;

const ListMenu = ({ info, onClick }: IMenuProps) => {
  const handleClick = () => {
    info.scrollTo();
    window.innerWidth <= 768 && onClick();
  };

  return (
    <li className={styles.HeaderNavItem}>
      <button
        className={classNames(styles.HeaderNavBtn, styles.HeaderNavBtnLink)}
        onClick={handleClick}
      >
        {info.text}
      </button>
    </li>
  );
};


