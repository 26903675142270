import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icons from '../Icons';

import { IHero } from './Hero.interface';
import { ISocial } from '../../interfaces/social';

import ImgValikPavlenko from './../../assets/images/ValikPavlenko.jpg';
import ImgHeroBgI from './../../assets/images/hero-bg.jpg';
import ArrayTypesComponent from '../array-typed/ArrayTypes.component';

import { SOCIAL } from '../../constants';

import styles from './Hero.module.scss';

const HeroComponent = forwardRef(({ className }: IHero, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  const { t } = useTranslation();

  const profession: Array<string> = ['React', 'WordPress'];

  return (
    <section
      className={classNames(className, styles.Hero, 'bg-image')}
      style={{ backgroundImage: `url(${ImgHeroBgI})` }}
      ref={ref}
    >
      <article className={classNames(styles.HeroContainer, 'container', 'bg-image', 'd-flex')}>
        <img className={styles.HeroContainerImg} src={ImgValikPavlenko} alt={t('hero.name')} loading="lazy" />
        <h1 className={styles.HeroContainerTitle}>{t('hero.name')}</h1>
        <h2 className={styles.HeroContainerProfesion}>
          <span>
            <ArrayTypesComponent array={profession} speed={150} />
          </span>
          |
        </h2>

        <ul className={classNames(styles.HeroContainerSocial, 'd-flex')}>
          {SOCIAL.map((item, index) => (
            <SociolItem key={index} title={item.title} link={item.link} />
          ))}
        </ul>
      </article>
    </section>
  );
});

export default HeroComponent;

const SociolItem = ({ title, link }: ISocial) => (
  <li className={styles.HeroContainerSocialItem}>
    <a href={link} target="_blank" title={title} className={styles.HeroContainerSocialItemLink}>
      <Icons name={title} />
    </a>
  </li>
);
