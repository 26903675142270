import { IProject } from '../../interfaces/portfolio';

export const PORTFOLIO: Array<IProject> = [
  {
    name: 'Gala Foods',
    technologies: ['React'],
    link: 'https://galafoods.com.ua/en/',
    img: './media/project/galafoods.png'
  },
  {
    name: 'Decora horeca',
    technologies: ['WordPress', 'WooCommerce'],
    link: 'https://www.decora-horeca.com.ua/',
    img: './media/project/decore-horeca.jpg'
  },
  {
    name: 'Gradevent',
    technologies: ['WordPress'],
    link: 'https://www.gradevent.com.ua/',
    img: './media/project/gradevent.jpg'
  },
  {
    name: 'Pablito',
    technologies: ['WordPress', 'WooCommerce'],
    link: 'https://pablito.com.ua/',
    img: './media/project/pablito.svg'
  },
  {
    name: 'Get DevourNow',
    technologies: ['WordPress', 'Vue.js'],
    link: 'https://getdevournow.com/',
    img: './media/project/devournow.svg'
  },
  {
    name: 'Vycherpno',
    technologies: ['WordPress'],
    link: 'https://vycherpno.ck.ua/',
    img: './media/project/vycherpno.svg'
  },
  {
    name: 'Rent express',
    technologies: ['WordPress'],
    link: 'https://rentex.pl.ua/',
    img: './media/project/rentex.png'
  },
  {
    name: 'Print for you',
    technologies: ['WordPress', 'WooCommerce'],
    link: 'https://printforyou.com.ua/',
    img: './media/project/printforyou.jpg'
  },
  {
    name: 'VenStop Pro',
    technologies: ['Next.js', 'React'],
    link: 'https://venstop.pro/',
    img: './media/project/venstoppro.jpg'
  },
  {
    name: 'Makers Germany',
    technologies: ['WordPress'],
    link: 'https://makers-germany.com/',
    img: './media/project/makers-germany.jpg'
  },
  {
    name: 'Goodzapa',
    technologies: ['WordPress'],
    link: 'https://goodzapa.com.ua/',
    img: './media/project/goodzapa.jpg'
  },
  {
    name: 'Your auto window',
    technologies: ['WordPress'],
    link: 'https://tvoyoautosteklo.od.ua/',
    img: './media/project/tvoyoautosteklo.png'
  },
  {
    name: 'Amir Capital',
    technologies: ['WordPress'],
    text: 'portfolio.onlySite',
    link: 'https://amir.capital/',
    img: './media/project/amircapital.jpg'
  },
  {
    name: 'Trading house POLYAKOV',
    technologies: ['WordPress', 'WooCommerce'],

    link: 'https://polyakovtd.com.ua/',
    img: './media/project/polyakovtd.svg'
  },
  {
    name: 'Miss chsbc',
    technologies: ['HTML CSS PHP'],
    link: 'https://www.miss-chsbc.pavlenko.org.ua/',
    img: './media/project/miss-chsbc.jpg'
  }
];
