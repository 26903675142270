import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IContact } from './Contact.interface';

import { SOCIAL } from '../../constants';

import Icons from '../Icons';

import styles from './Contact.module.scss';

const ContactComponent = forwardRef(({ className, id, ...rest }: IContact, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <section
      id={id}
      className={classNames(className, styles.Contact, 'section')}
      {...rest}
      ref={ref}
    >
      <div className="container">
        <h2 className={classNames(styles.ContactTitle, 'textUnderlineLine')}>{t('contact.title')}</h2>
        <a className={styles.ContactMailPhone}
           href="mailto:valik.s.pavlenko@gmail.com">valik.s.pavlenko@gmail.com</a>
        <ul className={styles.ContactList}>
          {SOCIAL.map((item, index) => (
            <li key={index} className={styles.ContactListItem}>
              <a
                className={styles.ContactListItemLink}
                href={item.link}
                title={item.title}
                target="_blank"
              >
                <Icons
                  key={index}
                  name={item.title}
                />
              </a>
            </li>

          ))}
        </ul>

      </div>
    </section>
  );
});

export default ContactComponent;
