import classNames from 'classnames';
import Button from '../../Button';

import { IButton } from './ButtonCategory.interface';

import styles from './ButtonCategory.module.scss';

const ButtonCategory = ({ name, active, id, setFilter }: IButton): JSX.Element => {
  return (
    <Button
      onClick={() => setFilter(id)}
      className={classNames(styles['ButtonCategory'], {
        [styles.active]: active
      })}
    >
      {name}
    </Button>
  );
};

export default ButtonCategory;
