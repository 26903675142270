import { createContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// A global utility for tracking the current input method (mouse, keyboard or touch).
// https://github.com/ten1seven/what-input/
import 'what-input';

import HeroComponent from './components/Hero';
import SkillsComponent from './components/Skills';
import PortfolioComponent from './components/Portfolio';
import EducationComponent from './components/Education';
import ContactComponent from './components/Contact';
import Layout from './components/Layout';

import './App.scss';

export interface menuContextOptions {
  activePointer: number;
}

export const MenuContext = createContext<number>(0);

function App() {
  const { t } = useTranslation();

  const homeSection = useRef<HTMLDivElement>(null);
  const skillsSection = useRef<HTMLDivElement>(null);
  const portfolioSection = useRef<HTMLDivElement>(null);
  const educationSection = useRef<HTMLDivElement>(null);
  const contactSection = useRef<HTMLDivElement>(null);

  const toHome = () => {
    homeSection.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };
  const toSkills = () => {
    skillsSection.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };
  const toPortfolio = () => {
    portfolioSection.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };
  const toExperience = () => {
    educationSection.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };
  const toContact = () => {
    contactSection.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const toScroll = {
    home: toHome,
    skills: toSkills,
    portfolio: toPortfolio,
    experience: toExperience,
    contact: toContact
  };

  return (
    <Layout toScroll={toScroll}>
      <HeroComponent ref={homeSection} />
      <SkillsComponent ref={skillsSection} />
      <PortfolioComponent ref={portfolioSection} />
      <EducationComponent
        id="experience"
        ref={educationSection}
      />
      <ContactComponent
        id="contact"
        ref={contactSection}
      />
    </Layout>
  );
}

export default App;
