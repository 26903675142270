import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderComponent from '../Header/Header.component';

import { ILayout } from './Layout.interface';

const Layout: FC<ILayout> = ({ children, className, toScroll, ...rest }) => {
  const { t } = useTranslation();
  const menu = [
    {
      active: true,
      scrollTo: toScroll.home,
      text: t('header.home')
    },
    {
      scrollTo: toScroll.skills,
      text: t('header.skills')
    },
    {
      scrollTo: toScroll.portfolio,
      text: t('header.portfolio')
    },
    {
      scrollTo: toScroll.experience,
      text: t('header.experience')
    },
    {
      scrollTo: toScroll.contact,
      text: t('header.contact')
    }
  ];

  return (
    <>
      <HeaderComponent logoText="Valik" menu={menu} />

      <main className={className} {...rest}>
        {children}
      </main>
      <footer />
    </>
  );
};

export default Layout;
