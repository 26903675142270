import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';

import { IButton } from './Button.interface';

import styles from './Button.module.scss';

const Button = forwardRef(({
  variant = 'primary',
  className,
  children,
  ...rest
}: IButton, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => (
  <button
    role="button"
    className={classNames(className, styles.Button, {
      [styles['Button--primary']]: variant === 'primary'
    })}
    {...rest}
  >
    {children}
  </button>
));

export default Button;
